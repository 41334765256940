.container-payment-success {
  height: 100%;

  .box-success {
    padding: 10px 20px;
    width: 100%;
    max-width: 420px;
    aspect-ratio: 1 / 1.2;
    position: relative;

    background-color: #221e2a;
    border-radius: 32px;
    overflow: hidden;

    background-image: url("../media/green-bubbles-after.svg");
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 10%;

    .icon-status {
      width: 120px;
      height: 120px;
      object-fit: contain;
    }

    .text-white {
      font-size: 22px;
      font-weight: 500;
      color: #fff;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-image: url("../media/green-bubbles-before.svg");
      background-repeat: no-repeat;
      background-position: left bottom;
      background-size: 20%;
    }

    .btn-redirect {
      background: #ba81ff;
      border: none;
      color: #ffffff;
      margin-bottom: 0;

      &:hover {
        background: #ab66ff;
      }
    }

    .btn-keep-buy {
      background: linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%);
      border: none;
      color: #ffffff;

      &.disabled {
        background: #343a40;
        border: 1px solid #1d2124;
      }
    }
  }
}
