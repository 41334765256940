.wave-container {
  .wave {
    margin-top: 15px;
    float: left;
    width: 20px;
    height: 50px;
    box-sizing: border-box;
    border: 3px solid #ece61f;
    margin-left: -2px;
    animation: slide 0.6s infinite linear;

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &:nth-child(2n + 1) {
      animation-delay: 0.3s;
    }
  }
}

@keyframes slide {
  50% {
    margin-top: 30px;
  }
}
