.form-payment {
  width: 100%;
  padding: 40px;
  padding-bottom: 80px;

  @media (max-width: 991px) {
    padding: 20px 0px;
  }
}

.logo {
  width: 250px;
  height: auto;
  object-fit: contain;
}

.text-logo {
  white-space: nowrap;
  font-size: 35px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.box-card-type {
  top: 0;
  right: 0;
  height: 100%;
  pointer-events: none;
  z-index: 3;
}

.point-item-border {
  padding: 1px;
  border-radius: 8px;
  background-color: #1c1924;
  transition: all 0.3s;

  &:hover {
    background: linear-gradient(
      47deg,
      rgba(133, 255, 196, 1) 0%,
      rgba(92, 198, 255, 1) 50%,
      rgba(188, 133, 255, 1) 100%
    );
  }
}

.point-item {
  background-color: #1c1924;
  border-radius: 8px;
}

hr {
  margin: 10px 0 !important;
  margin-bottom: 0;
}

.img-card-type {
  height: 20px;
  object-fit: contain;
}

.input-card-number {
  z-index: 1;
}

.transition-field {
  transition: all 0.3s;
}

label {
  font-size: 14px;
  font-weight: bold;
}

.width-form-point {
  width: 50%;
  background-color: #332d3f;
  border-radius: 28px 0 0 28px;

  @media (max-width: 991px) {
    width: 100%;
    border-radius: 28px 28px 0 0;

    .current-package {
      background-image: none;
    }

    .box-coin {
      flex-direction: column-reverse;
    }
  }
}

.form-full-width {
  width: 100%;
}

.width-form-payment {
  width: 50%;
}

.btn-submit {
  height: 44px;
}

.total-pay {
  font-size: 42px;
  font-weight: 600;
}

.hidden {
  width: 0;
  opacity: 0;
  height: 0 !important;
  overflow: hidden;
  padding: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}

.text-point {
  text-align: center;
  font-size: 20px;
}

.text-money {
  text-align: center;
  font-size: 20px;
}

.width-current-package {
  max-width: 250px;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.clear-current-point {
  top: 0;
  right: 0;
  font-size: 25px;
  margin-top: -20px;
  margin-right: -10px;
  color: #a71d2a;
  cursor: pointer;
}

.point-package,
.payment-info {
  opacity: 1;
  border-radius: 28px;
}

.payment-info,
.current-package {
  padding: 20px;
  transition: opacity 0.3s;
}

.list-package-point {
  padding: 20px;
  background-color: #221e2b;
  border-radius: 28px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

.form-payment-container {
  background-color: #221e2b;
  border-radius: 0 28px 28px 0;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);

  @media (max-width: 991px) {
    border-radius: 0 0 28px 28px;

    .box-title-form-payment {
      display: none;
    }
  }
}

.two-form-content {
  @media (max-width: 991px) {
    flex-direction: column;

    .point-package,
    .payment-info {
      width: 100%;
    }
  }
}

.rccs__name,
.rccs__expiry > div:first-child {
  display: none;
}

.text-error {
  color: #ff3333;
  font-size: 14px;
}

.input-error {
  border-color: #ff3333 !important;
  box-shadow: 0px 0px 32px -15px rgba(255, 51, 51, 1) !important;
}

.btn-back,
.btn-confirm {
  height: 44px;
}

.btn-back {
  background: #ba81ff;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;

  &:hover {
    background: #ab66ff;
  }

  &:disabled {
    background: #ab66ff;
  }
}

.btn-submit,
.btn-confirm {
  background: linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%);
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;

  &.disabled {
    background: #343a40;
    border: 1px solid #1d2124;
  }
}

.payment-success {
  .icon-check {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .text-success {
    color: #0c9921;
  }
}

.fetching-data {
  min-height: 400px;

  @media (max-width: 991px) {
    min-height: fit-content;
  }
}

.box-empty {
  width: 100px;
  height: 100px;
  opacity: 0.5;
  box-shadow: 0 10px 6px -6px rgb(116, 110, 110);
}

.height-fit-content {
  height: fit-content;
}

.show-error-payment {
  font-size: 16px;
  background: #e0b3b3;
  color: #7d2d2d;
}

input,
.StripeElement {
  display: block;
  padding: 10px 14px;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  height: 40px !important;
  z-index: 999;
}

.input-style {
  background-color: #332d3f !important;
  color: #ffffff;
  border: 1px solid #57575c;

  &.disabled {
    background-color: #2c2c2f !important;
  }
}

input::placeholder {
  color: #aab7c4;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.box-space {
  width: 40px;
  transition: all 0.3s;

  .icon {
    font-size: 30px;
  }

  &:hover {
    color: #007bff;
  }
}

.rccs {
  max-width: 290px;
  width: 100%;

  .rccs__card {
    width: 100%;
  }
}

.notification-container {
  width: 350px !important;

  .notification {
    box-shadow: none;
  }
}

.current-package {
  background-image: url("../media/map-jp.svg");
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: right bottom;

  @media (max-width: 991px) {
    .title-package {
      display: none;
    }
  }

  @media (min-width: 992px) {
    .title-package-mobile {
      display: none;
    }
  }
}

.box-coin {
  display: flex;
  flex-direction: column;
}

.current-point {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 991px) {
    display: none;
  }
}

.current-point-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  img {
    height: 80px;
    object-fit: contain;
    margin-right: 20px;
  }

  .text-point {
    text-align: left;
  }

  .total-pay {
    font-size: 40px;
  }

  @media (min-width: 992px) {
    display: none;
  }
}

.space-1 {
  line-height: 22px;
  min-height: 22px;
}

.space-2 {
  line-height: 22px;
  min-height: 44px;
}

button {
  height: 40px;
}
