@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");
body {
  margin: 0;
  font-family: -apple-system, "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  background-color: #f6f8fb !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #191720;
  color: #FFF;
}