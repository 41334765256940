.box-language {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  margin-right: 20px;
  margin-top: 20px;

  img {
    width: 40px;
    height: auto;
    object-fit: contain;
    margin: 10px;
    opacity: 0.5;
    transition: opacity 0.3;
    cursor: pointer;

    &.active {
      opacity: 1;
    }

    @media (max-width: 576px) {
      width: 30px;
    }
  }
}
