.container-payment-failure {
  height: 100%;

  .box-error {
    padding: 10px 20px;
    width: 100%;
    max-width: 420px;
    aspect-ratio: 1;
    position: relative;

    background-color: #221e2a;
    border-radius: 32px;
    overflow: hidden;

    .icon-status {
      width: 120px;
      height: 120px;
      object-fit: contain;
    }

    .text-fail {
      font-size: 22px;
      font-weight: 500;
      color: #fff;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-image: url("../media/red-bubbles-before.svg");
      background-repeat: no-repeat;
      background-position: left bottom;
      background-size: 20%;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;

      background-image: url("../media/red-bubbles-after.svg");
      background-repeat: no-repeat;
      background-position: right top;
      background-size: 10%;
    }
  }
}
